<template>
    <!-- 事务所 -->
    <div>
        <el-row>
            <el-col :span="24">
                <img class="medical-bg" src="../../img/solution/officeSolution/office_bg.png">
            </el-col>
            <el-col :span="24" class="solution-title">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">事务所</el-col>
                <el-col :span="17" class="center-el-col"><div class="solution-line"></div></el-col>
                <el-col :span="14" class="center-el-col">
                    <p class="text-center" style="color: #666666;">某律师事务所成立于1999年，是一家提供一站式法律服务的综合性律师事务所，在核心业务领域具备行业领先优势。发轫于中国上海，已在中国大陆二十一个城市及中国香港、英国伦敦和美国西雅图开设分所，有大量的文档资料需要传输、协作。</p>
                </el-col>
                <el-col :span="14" class="center-el-col">
                    <div class="title-end">
                        <div class="solution-line2"></div>
                        <div>客户背景</div>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="24" class="solution-pain">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">行业痛点</el-col>
                <div class="pain-cards">
                    <div class="pain-card">
                        <div><img src="../../img/solution/medicalSolution/icon1.png"></div>
                        <div class="text-center">卷宗分散 管理困难</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">一旦律师离职之后，会造成之前文档丢失。会造成之前文档丢失。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/medicalSolution/icon2.png"></div>
                        <div class="text-center">文档归类难整理</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">与客户间往来电子邮件及相关文档很难有效归档。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/medicalSolution/icon3.png"></div>
                        <div class="text-center">项目协作效率低</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">海量文档卷宗搜索、查找非常困难，项目文档协作和共享不方便。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/medicalSolution/icon4.png"></div>
                        <div class="text-center">文档安全性低</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">文档卷宗安全性和保密性管理缺失。</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-col :span="4" :sm="10" :xs="12" class="medicalSolution">伊登云解决方案</el-col>
                <el-col :span="15" class="center-el-col">
                    <div class="eden-solution-plate">
                        <div class="plate-info">
                            <p class="eden-solution-title">>>项目文件的共享和协作</p>
                            <p class="eden-solution-content">团队成员对项目文件的传输、共享和协作。</p>

                            <p class="eden-solution-title">>>策略化版本控制</p>
                            <p class="eden-solution-content">针对不同管理级别的文档，灵活设置版本管理策略。签入签出功能，保证文档的唯一性，防止文档版本混乱。</p>

                            <p class="eden-solution-title">>>文件权限管理</p>
                            <p class="eden-solution-content">管理员可以根据需要对员工设置文件夹的访问权限，保证不同的项目成员在各自职责范围内协作，保障文档的安全性。</p>

                            <p class="eden-solution-title">>>自动文档分类和知识管理</p>
                            <p class="eden-solution-content">确保最佳的诉讼手续、判例和专业知识有序分类，并通过强大的全文搜索功能，快速、准确的查找到所需的相关文档。</p>
                        </div>
                        <img class="medical-bg" src="../../img/solution/officeSolution/office_img1.png">
                    </div>
                </el-col>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "officeSolution",
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
    .medical-bg {
        width: 100%;
    }

    .center-el-col {
        margin: auto;
        float: unset;
    }

    .solution-line {
        border: 1px solid #E5E5E5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .solution-line2 {
        border: 1px solid #666666;
        width: 2rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        height: 0px;
    }

    .solution-blue-line {
        width: 48px;
        height: 6px;
        background: #2974E4;
    }

    .medicalSolution {
        margin: auto;
        float: unset;
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        color: #3764D7;
        text-align: center;
        width: auto;
    }

    .solution-title {
        padding: 3rem 0px 1.5rem 0px;
    }

    .title-end {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .solution-pain {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    * {
        font-size: 16px
    }

    .pain-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pain-cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        width: 100%;
        max-width: 1110px;
        margin: 2rem auto;
    }

    .pain-card {
        max-width: 33rem;
        width: 100%;
        text-align: center;
        padding: 0.75rem;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
    }

    .pain-card1 {
        border-bottom: 0px;
    }

    .pain-content {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }

    .eden-solution-plate {
        max-width: 1200px;
        width: 100%;
        margin: 2rem auto;
    }
    .plate-info {
        max-width: 600px;
        display: inline-block;
    }
    .eden-solution-plate img {
        width: 287px;
        height: 291px;
        vertical-align: top;
        padding-left: 5%;
    }

    .eden-solution-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold
    }

    .eden-solution-content {
        color: #333333;
        font-size: 14px;
    }

    @media screen and (max-width: 1494px){
        .plate-info {
            max-width: 1200px;
        }

        .eden-solution-plate img{
            display: none;
        }
    }

    @media screen and (min-width: 1200px){
        .pain-cards .pain-card:nth-child(2n) {
            border-right: 0px;
        }
    }


    @media screen and (max-width: 1200px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 800px;
        }

        .plate-info {
            max-width: 800px;
        }

        .eden-solution-plate img{
            display: none;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }

    @media screen and (max-width: 800px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 400px;
        }

        .plate-info {
            max-width: 400px;
        }

        .eden-solution-plate img{
            display: none;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }
</style>